import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionProductsList.module.css';
import { SectionListingCard } from '../../../../components';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionProductsList = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    leftTitle,
    currentUser,
    listings,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const computedBlocks = blocks ? blocks.filter(b => b.blockName == 'products-id') : [];
  const mediaBlocks = blocks ? blocks.filter(b => b.blockName == 'category-picture') : [];

  const limitedListings = listings.slice(0, 8);

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={classNames(defaultClasses.sectionDetails, css.sectionCategoryTitle, {
          [defaultClasses.leftSection]: leftTitle,
        })}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      <div
        className={classNames(defaultClasses.blockContainer, css.articleMain, css.sectionProductsContainer, {
          [css.noSidePaddings]: isInsideContainer,
        })}
      >
        {mediaBlocks.length && <BlockBuilder
          className={css.mediaBlock}
          blocks={[mediaBlocks[0]]}
          sectionId={sectionId}
          ctaButtonClass={defaultClasses.ctaButton}
          options={options}
        />}
        {limitedListings && limitedListings?.map((l, index) => (
          <SectionListingCard
            key={l.id.uuid}
            currentUser={currentUser}
            listing={l}
            // renderSizes={cardRenderSizes}
            // hideSavedListing={true}
            // onUpdateSavedListings={() => {}}
            // setActiveListing={setActiveListing}
          />
          // <Field data={{content: link, fieldType: 'markdown'}} options={options} key={`link_${index}`}/>
        ))}
      </div>
      {/* {hasBlocks ? (
        <div
          className={classNames(defaultClasses.blockContainer, css.articleMain, {
            [css.noSidePaddings]: isInsideContainer,
          })}
        >
          <BlockBuilder
            blocks={computedBlocks}
            sectionId={sectionId}
            ctaButtonClass={defaultClasses.ctaButton}
            options={options}
          />
        </div>
      ) : null} */}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionProductsList.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionProductsList.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionProductsList;
