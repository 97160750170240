import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockCategoryCarouselItem.module.css';
import { Link } from '../../Primitives/Link';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockCategoryCarouselItem = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  let container = <div className={css.carouselItemContainer}>
    <FieldMedia
      media={media}
      sizes={responsiveImageSizes}
      className={classNames(css.carouselItemMedia, mediaClassName)}
      options={options}
    />
    {hasTextComponentFields ? (
      <div className={classNames(textClassName, css.text)}>
        <Field data={title} options={options} />
        <Field data={text} options={options} />
        {/* <Field data={callToAction} className={ctaButtonClass} options={options} /> */}
      </div>
    ) : null}
  </div>;

  const isLink = callToAction && callToAction?.fieldType == 'internalButtonLink';
  if (isLink) {
    container = <Link href={callToAction.href}>{container}</Link>;
  }

  return (
    <BlockContainer id={blockId} className={classes}>
      {container}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockCategoryCarouselItem.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockCategoryCarouselItem.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockCategoryCarouselItem;
