import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockSearchBar.module.css';
import TopbarSearchForm from '../../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';

import { useIntl } from 'react-intl';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockSearchBar = props => {
  const {
    blockId,
    appConfig,
    onSearchSubmit,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    isLanding,
  } = props;

  let intl = useIntl();

  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const showAll = !isLanding;
  const placeholder = isLanding ? intl.formatMessage({
    id: 'TopbarSearchForm.landingSearchPlaceholder',
  }) : intl.formatMessage({
    id: 'TopbarSearchForm.categorySearchPlaceholder',
  });

  const searchClassName = isLanding ? css.landingSearch : css.categorySearch;

  let initialSearchFormValues = {};
  if (text !== undefined && text !== null && text.content && text.content !== '') {
    let params = text.content.split('&');
    for (let param of params) {
      let varName = param.split('=');
      if (varName.length && varName.length == 2) {
        initialSearchFormValues[varName[0]] = varName[1];
      }
    }
  }

  const hasInitialValues = !(Object.keys(initialSearchFormValues).length === 0 && initialSearchFormValues.constructor === Object);

  const onSubmit = (values) => {
    let payload = {...values};

    if (hasInitialValues) {
      payload = {
        ...values,
        ...initialSearchFormValues
      };
    }

    onSearchSubmit(payload);
  };

  const searchAll = () => {
    if (hasInitialValues) {
      let payload = {
        ...initialSearchFormValues
      };

      onSearchSubmit(payload);
    }
  };

  const search = (
    <TopbarSearchForm
      className={classNames(css.searchLink, searchClassName)}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSubmit}
      // initialValues={initialSearchFormValues}
      appConfig={appConfig}
      placeholder={placeholder}
    />
  );

  return (
    <BlockContainer id={blockId} className={classes}>
      {search}
      {showAll ? <div onClick={searchAll} className={css.seeAll}>See all listings</div> : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockSearchBar.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockSearchBar.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockSearchBar;
